<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="12" md="8" sm="8">
            <v-card width="500" class="mx-auto my-card" :loading="loading">
              <v-card-text>
                <h5 class="text-center">Partner Login</h5>
                <v-form @submit.prevent="loginUser">
                  <v-text-field
                      filled
                      type="email"
                      label="Email"
                      prepend-icon="email"
                      v-model="user.email"
                  />
                  <v-text-field
                      filled
                      v-model="user.password"
                      :type="pw_type"
                      label="Password"
                      prepend-icon="lock"
                      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword"
                  />
                  <router-link to="/forgot-password">
                    Forgot Password?
                  </router-link>
                  <br>
                  <v-btn type="submit" color="red darken-2 mt-3" block dark>Login</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "Login",
  data() {
    return {
      showPass: false,
      user: {
        email: '',
        password: ''
      },
      pw_type: 'password',
      loading: false
    }
  },
  methods: {
    loginUser() {
      this.loading = true
      this.$store.dispatch('login', this.user)
          .then(() => {
            this.loading = false
            this.$router.push('/dashboard')
          })
          .catch(err => {
            this.loading = false
            alert(err)
          })
    },
    showPassword(){
      this.showPass = !this.showPass
      if(this.pw_type === 'password'){
        this.pw_type = 'text'
      }else{
        this.pw_type = 'password'
      }
    }
  }
}
</script>

<style scoped>
.my-card {
  overflow: hidden;
}
</style>